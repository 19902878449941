import axios from 'axios'
import OrderApi from './OrderApi'
import UserApi from './UserApi'
import VouchersApi from "./VouchersApi";
import ReturnsApi from "./ReturnsApi";
import HealthService from "./health-service";
import ShopApi from './ShopApi'

axios.defaults.baseURL =  process.env.VUE_APP_BACKEND_HOST;

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});

export {
    OrderApi,
    UserApi,
    VouchersApi,
    ReturnsApi,
    HealthService,
    ShopApi
}
